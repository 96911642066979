import React     from 'react';
import PropTypes from 'prop-types';

const DImage = ({ name, alt, assetKey, width, height, fit, quality }) => {
  // const [imgLoaded, setImgLoaded] = useState(false);
  const baseUrl = `${process.env.GATSBY_API_ENDPOINT}/145/assets`;
  const buildQueryParameters = () => {
    const paramsArray = [];
    if (assetKey) {
      paramsArray.push(`key=${assetKey}`);
    }

    if (!assetKey && width) {
      paramsArray.push(`w=${width}`);
    }

    if (!assetKey && height) {
      paramsArray.push(`h=${height}`);
    }

    if (fit) {
      paramsArray.push(`f=${fit}`);
    }

    if (quality) {
      paramsArray.push(`q=${quality}`);
    }

    if (paramsArray.length !== 0) {
      return `${paramsArray.join('&')}`;
    } else {
      return;
    }
  };

  return <img src={`${baseUrl}/${name}?${buildQueryParameters()}`} alt={alt} title={alt}/>
};

DImage.propTypes = {
  name: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  assetKey: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fit: PropTypes.oneOf(['crop', 'contain']),
  quality: PropTypes.number
};

DImage.defaultProps = {
  quality: 85,
};

export default DImage;
